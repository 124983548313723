$sizes: (8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100);
@each $size in $sizes {
    .fs-#{$size} {
        font-size: $size * 1px;
    }
    .lh-#{$size} {
        line-height: $size * 1px;
    }
}
$tipos: (oblique, normal, italic);
$larguras: (bold, bolder, 900, 800, 700, 600, 500, 400, 300, 200, 100, lighter, normal);
@each $tipo in $tipos {
    .font-#{$tipo} {
        font-style: $tipo;
    }
}
@each $larg in $larguras {
    .font-#{$larg} {
        font-weight: $larg;
    }
}

$decoracoes: (underline, unset, blink, dashed, none);
@each $decor in $decoracoes {
    .text-decoration-#{$decor} {
        text-decoration: $decor;
    }
}

$opacitys: (1,2,3,4,5,6,7,8,9);
@each $opacity in $opacitys {
    .opacity-#{$opacity} {
        opacity: $opacity * 0.1; 
    }
}