/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "theme/helpers/all";
@import "theme/components/all";

full-calendar{
    // .fc-daygrid-day-events{
    //     text-align: center;        
    //     .fc-daygrid-event{
    //         &.agendamento{
    //             border-radius: 50% !important;
    //             display: inline-block !important;
    //             width: 25px !important;
    //             height: 25px !important;
    //         }
    //     }
    // }
    
    .fc-toolbar-title{
        font-size: 1.3em !important;
    }
}


.header {
    &.no-bd{
        --border-width: 0;
    }

    img.logo{
        max-height: 36px;
    }

    .notificacao-button {
        position: relative;
        .badge{
            background-color: var(--ion-color-danger);
            border-radius: 5px;
            height: 10px;
            position: absolute;
            right: 0;
            top: 0;
            width: 10px;
        }
    }
}