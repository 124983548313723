//== Gradient background 
@mixin gradient-bg($alternative-bg, $degree, $from, $to) {
    background: $alternative-bg; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient( $degree, $from, $to ); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient( $degree, $from, $to ); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient( $degree, $from, $to ); /* For Firefox 3.6 to 15 */
    background: linear-gradient( $degree, $from, $to ) !important;/* Standard syntax */    
}    

//== Fixed positioned element smooth
@mixin fix-animation-lags() {
    @include transform(translateZ(0)); 
    -webkit-transform-style: preserve-3d;
}

//== Box Shadow
@mixin shadow($shadow, $important: '') {
    -webkit-box-shadow: #{$shadow} #{$important}; // iOS <4.3 & Android <4.1
    -moz-box-shadow: #{$shadow} #{$important};
    box-shadow: #{$shadow} #{$important};
}

@mixin transform($degree) {  
    -webkit-transform: #{$degree};
    -moz-transform:#{ $degree};
    -ms-transform: #{$degree};
    -o-transform: #{$degree};
    transform: #{$degree};
}

//== Animation
@mixin animation($animation) {
    -webkit-animation: #{$animation};
    -moz-animation: #{$animation};
    -ms-animation: #{$animation};
    -o-animation: #{$animation};
    animation: #{$animation};
}

//== Animation FIll Mode
@mixin animation-fill-mode($mode) {
    -webkit-animation-fill-mode: #{$mode};
    -moz-animation-fill-mode: #{$mode};
    -ms-animation-fill-mode: #{$mode};
    -o-animation-fill-mode: #{$mode};
    animation-fill-mode: #{$mode};
}

//== Transition
@mixin transition($transition) {
    -webkit-transition: #{$transition};
    -moz-transition: #{$transition};
    -ms-transition: #{$transition};
    -o-transition: #{$transition};
    transition: #{$transition};
}

//== Keyframes
@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin icon-color-style($color) {
    border-color: $color;
    ion-icon.primary-icon{
        color : $color;
    }
    ion-icon.secondary-icon{
        background: $color;
    }
}