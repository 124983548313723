@function shade($color, $percentage) {
    @return mix(black, $color, $percentage);
}
@function tint($color, $percentage) {
    @return mix(white, $color, $percentage);
}
@function contrast($color) {
    @if (red($color) * 0.299 + green($color) * 0.587 + blue($color) * 0.114) > 186 {
        @return #000000;
    } @else {
        @return #ffffff;
    }
}

$primary: #a3c3e4;
$secondary: #002A54;
$success: #8CC63F;
$warning: #FBB03B;
$danger: #FF0000;
$dark: #002A54;
$white: #FFFFFF;


// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    --background: #{$white};
    --ion-background-color: #{$white};
    --ion-background-color-rgb: #{red(contrast($white))}, #{green(contrast($white))}, #{blue(contrast($white))};

    --ion-font-family: 'Open Sans', sans-serif;

    /** primary **/
    --ion-color-primary: #{$primary};
    --ion-color-primary-rgb: #{red($primary)}, #{green($primary)}, #{blue($primary)};
    --ion-color-primary-contrast: #{$white};
    --ion-color-primary-contrast-rgb: #{red(contrast($primary))}, #{green(contrast($primary))}, #{blue(contrast($primary))};
    --ion-color-primary-shade: #{shade($primary, 12%)};
    --ion-color-primary-tint: #{tint($primary, 10%)};

    /** secondary **/
    --ion-color-secondary: #{$secondary};
    --ion-color-secondary-rgb: #{red($secondary)}, #{green($secondary)}, #{blue($secondary)};
    --ion-color-secondary-contrast: #{contrast($secondary)};
    --ion-color-secondary-contrast-rgb: #{red(contrast($secondary))}, #{green(contrast($secondary))}, #{blue(contrast($secondary))};
    --ion-color-secondary-shade: #{shade($secondary, 12%)};
    --ion-color-secondary-tint: #{tint($secondary, 10%)};

    /** tertiary **/
    --ion-color-tertiary: #151cc2;
    --ion-color-tertiary-rgb: 21, 28, 194;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #1219ab;
    --ion-color-tertiary-tint: #2c33c8;

    /** success **/
    --ion-color-success: #{$success};
    --ion-color-success-rgb: #{red($success)}, #{green($success)}, #{blue($success)};
    --ion-color-success-contrast: #{contrast($success)};
    --ion-color-success-contrast-rgb: #{red(contrast($success))}, #{green(contrast($success))}, #{blue(contrast($success))};
    --ion-color-success-shade: #{shade($success, 12%)};
    --ion-color-success-tint: #{tint($success, 10%)};

    /** warning **/
    --ion-color-warning: #{$warning};
    --ion-color-warning-rgb: #{red($warning)}, #{green($warning)}, #{blue($warning)};
    --ion-color-warning-contrast: #{contrast($warning)};
    --ion-color-warning-contrast-rgb: #{red(contrast($warning))}, #{green(contrast($warning))}, #{blue(contrast($warning))};
    --ion-color-warning-shade: #{shade($warning, 12%)};
    --ion-color-warning-tint: #{tint($warning, 10%)};

    /** danger **/
    --ion-color-danger: #{$danger};
    --ion-color-danger-rgb: #{red($danger)}, #{green($danger)}, #{blue($danger)};
    --ion-color-danger-contrast: #{contrast($danger)};
    --ion-color-danger-contrast-rgb: #{red(contrast($danger))}, #{green(contrast($danger))}, #{blue(contrast($danger))};
    --ion-color-danger-shade: #{shade($danger, 12%)};
    --ion-color-danger-tint: #{tint($danger, 10%)};

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-color-blue: #4473c5;
    --ion-color-blue-rgb: 68, 115, 197;
    --ion-color-blue-contrast: #ffffff;
    --ion-color-blue-contrast-rgb: 255, 255, 255;
    --ion-color-blue-shade: #3c65ad;
    --ion-color-blue-tint: #5781cb;

    --ion-color-purple: #7030a0;
    --ion-color-purple-rgb: 112, 48, 160;
    --ion-color-purple-contrast: #ffffff;
    --ion-color-purple-contrast-rgb: 255, 255, 255;
    --ion-color-purple-shade: #632a8d;
    --ion-color-purple-tint: #7e45aa;

    --ion-color-lightblue: #01b0f1;
    --ion-color-lightblue-rgb: 1, 176, 241;
    --ion-color-lightblue-contrast: #000000;
    --ion-color-lightblue-contrast-rgb: 0, 0, 0;
    --ion-color-lightblue-shade: #019bd4;
    --ion-color-lightblue-tint: #1ab8f2;

    --ion-color-darkblue: #012060;
    --ion-color-darkblue-rgb: 1, 32, 96;
    --ion-color-darkblue-contrast: #ffffff;
    --ion-color-darkblue-contrast-rgb: 255, 255, 255;
    --ion-color-darkblue-shade: #011c54;
    --ion-color-darkblue-tint: #1a3670;

    --ion-color-pink: #c40db7;
    --ion-color-pink-rgb: 196, 13, 183;
    --ion-color-pink-contrast: #ffffff;
    --ion-color-pink-contrast-rgb: 255, 255, 255;
    --ion-color-pink-shade: #ac0ba1;
    --ion-color-pink-tint: #ca25be;

    --ion-color-black: #404040;
    --ion-color-black-rgb: 64, 64, 64;
    --ion-color-black-contrast: #ffffff;
    --ion-color-black-contrast-rgb: 255, 255, 255;
    --ion-color-black-shade: #383838;
    --ion-color-black-tint: #535353;
}

.ion-color-blue {
    --ion-color-base: var(--ion-color-blue);
    --ion-color-base-rgb: var(--ion-color-blue-rgb);
    --ion-color-contrast: var(--ion-color-blue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue-shade);
    --ion-color-tint: var(--ion-color-blue-tint);
}

.ion-color-purple {
    --ion-color-base: var(--ion-color-purple);
    --ion-color-base-rgb: var(--ion-color-purple-rgb);
    --ion-color-contrast: var(--ion-color-purple-contrast);
    --ion-color-contrast-rgb: var(--ion-color-purple-contrast-rgb);
    --ion-color-shade: var(--ion-color-purple-shade);
    --ion-color-tint: var(--ion-color-purple-tint);
}

.ion-color-lightblue {
    --ion-color-base: var(--ion-color-lightblue);
    --ion-color-base-rgb: var(--ion-color-lightblue-rgb);
    --ion-color-contrast: var(--ion-color-lightblue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-lightblue-contrast-rgb);
    --ion-color-shade: var(--ion-color-lightblue-shade);
    --ion-color-tint: var(--ion-color-lightblue-tint);
}

.ion-color-darkblue {
    --ion-color-base: var(--ion-color-darkblue);
    --ion-color-base-rgb: var(--ion-color-darkblue-rgb);
    --ion-color-contrast: var(--ion-color-darkblue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-darkblue-contrast-rgb);
    --ion-color-shade: var(--ion-color-darkblue-shade);
    --ion-color-tint: var(--ion-color-darkblue-tint);
}

.ion-color-pink {
    --ion-color-base: var(--ion-color-pink);
    --ion-color-base-rgb: var(--ion-color-pink-rgb);
    --ion-color-contrast: var(--ion-color-pink-contrast);
    --ion-color-contrast-rgb: var(--ion-color-pink-contrast-rgb);
    --ion-color-shade: var(--ion-color-pink-shade);
    --ion-color-tint: var(--ion-color-pink-tint);
}

.ion-color-black {
    --ion-color-base: var(--ion-color-black);
    --ion-color-base-rgb: var(--ion-color-black-rgb);
    --ion-color-contrast: var(--ion-color-black-contrast);
    --ion-color-contrast-rgb: var(--ion-color-black-contrast-rgb);
    --ion-color-shade: var(--ion-color-black-shade);
    --ion-color-tint: var(--ion-color-black-tint);
}
