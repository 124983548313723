.animate-fade-in-up {
    animation: animate-fade-in-up .5s;
}

.animate-fade-out {
    animation: animate-fade-out .5s;
}

.animate-fade-in {
    animation: animate-fade-in .5s;
}  

.animate-blink {
    animation: animate-blink 1s step-start 0s infinite;
    animation-fill-mode: initial;
}

.animate-shake {
    animation: animate-shake 0.1s ease-in 0.1s infinite alternate;
    animation-fill-mode: initial;
}

.animate-pause {
    animation-play-state:paused;
}

.notransition {
    transition: none !important;
}


.flip-card {
    width: 100%;
    height: 250px;
    perspective: 1000px;
}
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}
.flip-card.flipper .flip-card-inner {
    transform: rotateY(180deg);
}
.flip-card canvas{
    transition: all ease-in-out 0.5s;
    opacity: 1;
}
.flip-card.flipper canvas{
    opacity: 0;    
}
.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}
.flip-card-front {
}
.flip-card-back {
    transform: rotateY(180deg);
}

//== Animations

@include keyframes(animate-fade-out) {
    from   { opacity: 1; }
    to { opacity: 0; }
}

@include keyframes(animate-fade-in) {
    from   { opacity: 0; }
    to { opacity: 1; }
}

@include keyframes(animate-fade-in-up) {
    from   { 
        opacity: 0; 
        transform: translateY(15px);
    }

    to { opacity: 1; 
         transform: translateY(0px);	
    }
}

@include keyframes(animate-fade-out-up) {
    from   { 
        opacity: 1; 
        transform: translateY(15px);
    }

    to { opacity: 0; 
         transform: translateY(0px);    
    }
}

@include keyframes(animate-blink) {
    50% {
        opacity: 0.0;
    }
}

@include keyframes(animate-shake) {
    from {
        transform: rotate(13deg);
    }

    to {
        -webkit-transform-origin:center center;
        transform: rotate(-13deg);
    }
}