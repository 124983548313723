$recuos: (0,1,2,3,4,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100);
@each $r in $recuos {
    .pd-#{$r}{
        padding: $r + px !important;
    }
    .pdt-#{$r}{
        padding-top: $r + px !important;
    }
    .pdb-#{$r}{
        padding-bottom: $r + px !important;
    }
    .pdl-#{$r}{
        padding-left: $r + px !important;
    }
    .pdr-#{$r}{
        padding-right: $r + px !important;
    }
    .pdx-#{$r} {
        padding-left: $r + px !important;
        padding-right: $r + px !important;
    }
    .pdy-#{$r} {
        padding-top: $r + px !important;
        padding-bottom: $r + px !important;
    }    
}