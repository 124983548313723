form{

    .form-group{

        font-size: 14px;
        margin-bottom: 15px;

        ion-label{            
            font-weight: bold;
            margin-bottom: 5px;
            display: block;
        }

        ion-input {
            //--background: var(--ion-color-light);
            --background: #FFFFFF;
            //--color: #ffffff;
            --padding-end: 10px;
            --padding-start: 10px;
            --padding-bottom: 10px;
            --padding-top: 10px;
            --placeholder-color:var(--ion-color-medium);
            //--placeholder-font-style: initial;
            //--placeholder-font-weight: initial;
            --placeholder-opacity: 1;

            border-radius: 10px;
            border: 1px solid var(--ion-color-medium);

            &:focus-within {
                border-color: var(--ion-color-secondary);
            }

            &.ion-invalid.ion-touched{
                border-color: var(--ion-color-danger);
                --padding-end: 30px;                
                background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FF4E4E' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF4E4E' stroke='none'/%3e%3c/svg%3e");
                background-repeat:no-repeat;
                background-position:right calc(.375em + .1875rem) center;
                background-size:calc(.75em + .375rem) calc(.75em + .375rem);
            }
        }

        ion-textarea {            
            --background: #FFFFFF;            
            --padding-end: 10px;
            --padding-start: 10px;
            --padding-bottom: 10px;
            --padding-top: 10px;
            --placeholder-color:var(--ion-color-medium);            
            --placeholder-opacity: 1;

            border-radius: 10px;
            border: 1px solid var(--ion-color-medium);

            &:focus-within {
                border-color: var(--ion-color-secondary);
            }

            &.ion-invalid.ion-touched{
                border-color: var(--ion-color-danger);
                --padding-end: 30px;                
                background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FF4E4E' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF4E4E' stroke='none'/%3e%3c/svg%3e");
                background-repeat:no-repeat;
                background-position:right calc(.375em + .1875rem) center;
                background-size:calc(.75em + .375rem) calc(.75em + .375rem);
            }
        }

        ion-select{
            --padding-end: 10px;
            --padding-start: 10px;
            --padding-bottom: 10px;
            --padding-top: 10px;
            --placeholder-color:var(--ion-color-medium);
            --placeholder-opacity: 1;
            background-color: #FFFFFF;

            border-radius: 10px;
            border: 1px solid var(--ion-color-medium);

            &:focus-within {
                border-color: var(--ion-color-secondary);
            }

            &.ion-invalid.ion-touched{
                border-color: var(--ion-color-danger);
                --padding-end: 30px;                
                background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FF4E4E' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF4E4E' stroke='none'/%3e%3c/svg%3e");
                background-repeat:no-repeat;
                background-position:right calc(.375em + .1875rem) center;
                background-size:calc(.75em + .375rem) calc(.75em + .375rem);
            }
        }

        input{
            background-color: #FFFFFF;            
            padding-top: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
            padding-left: 10px;
            border-radius: 10px;
            border: 1px solid var(--ion-color-medium);
            outline: none;
            width: 100%;

            &::placeholder {
                color: var(--ion-color-medium);
                opacity: 1;
            }

            &:focus{
                border-color: var(--ion-color-secondary);
            }

            &.ion-invalid.ion-touched{
                border-color: var(--ion-color-danger);
                padding-right: 30px;                
                background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23FF4E4E' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23FF4E4E' stroke='none'/%3e%3c/svg%3e");
                background-repeat:no-repeat;
                background-position:right calc(.375em + .1875rem) center;
                background-size:calc(.75em + .375rem) calc(.75em + .375rem);
            }

            &[type=search]{
                padding-right: 30px;                
                background-image:url("/assets/icon/search-outline.svg");
                background-repeat:no-repeat;
                background-position:right calc(.375em + .1875rem) center;
                background-size:calc(.75em + .375rem) calc(.75em + .375rem);
            }
        }

        ion-radio{
            margin-right: 15px;            
            width: 25px;
            height: 25px;
        }        
        ion-radio::part(mark){        
            border: 1px solid var(--ion-color-medium);        
            border-radius: 50%;
            transform: none;
            width: 18px;
            height: 18px;
        }
        ion-radio.radio-checked::part(mark){        
            border: 5px solid var(--ion-color-secondary);
            transform: none;
            border-radius: 50%;
        }

        ion-checkbox{
            margin: 4px 15px 0px 5px;
        }

        ion-range{
            padding-left: 0;
            padding-right: 0;
        }

        ion-item{
            --padding-start: 0;
            --padding-end: 0;
            font-size: 14px;         
        }
    }    

}