$recuos: (0,1,2,3,4,5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100);
@each $r in $recuos {
    .mg-#{$r} {
        margin: $r + px !important;        
    }
    .mgt-#{$r} {
        margin-top: $r + px !important;        
    }
    .mgb-#{$r} {
        margin-bottom: $r + px !important;
    }
    .mgr-#{$r} {
        margin-right: $r + px !important;        
    }
    .mgl-#{$r} {
        margin-left: $r + px !important;
    }
    .mgx-#{$r} {
        margin-left: $r + px !important;
        margin-right: $r + px !important;
    }
    .mgy-#{$r} {
        margin-top: $r + px !important;
        margin-bottom: $r + px !important;
    }
}

.mg-auto{
    margin: auto;
}
.mgy-auto{
    margin-top: auto;
    margin-bottom: auto;
}
.mgx-auto{
    margin-left: auto;
    margin-right: auto;
}
.mgl-auto{
    margin-left: auto;
}
.mgr-auto{
    margin-right: auto;
}
.mgt-auto{
    margin-top: auto;
}
.mgb-auto{
    margin-bottom: auto;
}